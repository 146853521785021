import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { useContext, useEffect, useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import { ListItem } from '@mui/material';
import NRAutocompleteStyles from './NRAutocomplete.styles';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { SBOM_CONSTANTS } from '../SBOM/SBOM.constants';
import NRCycloneDXIcon from '../../NRIcons/NRCycloneDXIcon';
import NRSPDXIcon from '../../NRIcons/NRSPDXIcon';
import ButtonGroup from '@mui/material/ButtonGroup';
import AssetAndGroupsButtons from '../NRAISearch/NRAISearchFilters/NRAutocomplete/AssetAndGroupsButtons';
import { AutocompleteGroupsAndAssetsContext } from '../NRAISearch/NRAISearchFilters/NRAutocomplete/NRAutocompleteGroupsAndAssetsContextWrapper';
const useStyles = makeStyles(NRAutocompleteStyles);

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13
  },
  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      backgroundColor: theme.palette.primary.contrastText,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent'
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]: {
        backgroundColor: theme.palette.action.hover
      }
    }
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative'
  }
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  boxShadow: `0 8px 24px ${theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'}`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: '10px 16px',
  width: '100%',
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& input': {
    borderRadius: 4,
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.divider}`,
    fontSize: 14
  }
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  width: '100%',
  textAlign: 'left',
  paddingBottom: 8,
  color: theme.palette.text.icon,
  fontWeight: 600,
  '& span': {
    width: '100%'
  },
  '& svg': {
    width: 16,
    height: 16
  }
}));

function NRAutocomplete({
  arrayData = [],
  value = [],
  setValue,
  loading,
  fullWidth = false,
  title,
  dataNameSingular = 'Group',
  fieldToShow = 'name',
  idField = 'id',
  showAlwaysTitle = false,
  assetsAndGroups = false
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { assetsSelected } = useContext(AutocompleteGroupsAndAssetsContext);
  const [pendingValue, setPendingValue] = useState(value || []);
  const theme = useTheme();
  const classes = useStyles();

  const handleClick = event => {
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValue([...pendingValue]);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  useEffect(() => {
    if (assetsAndGroups) {
      setPendingValue(value);
    }
  }, [assetsSelected]);

  const open = Boolean(anchorEl);
  const id = open ? `${dataNameSingular}s-filter` : undefined;

  const handleOnClear = e => {
    e?.stopPropagation();
    setPendingValue([]);
    setValue([]);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          ...(fullWidth ? {} : { width: theme.spacing(29) }),
          fontSize: 16
        }}
      >
        <Button aria-describedby={id} onClick={handleClick} className={classes.selectButton} disabled={!arrayData?.length} disableTouchRipple>
          {!!showAlwaysTitle && !!title && <>{title}</>}
          {!showAlwaysTitle && !arrayData?.length && !!loading && <Typography variant={'body1'} children={`Loading ${dataNameSingular}s...`} />}
          {!showAlwaysTitle && !arrayData?.length && !loading && <Typography variant={'body1'} children={`No ${dataNameSingular}s Present`} />}
          {!showAlwaysTitle && !!arrayData?.length && (
            <Typography variant={'body1'}>
              {value?.length ? `${value.length} ${dataNameSingular}${value.length > 1 ? 's' : ''} selected` : title ? title : dataNameSingular + 's'}
            </Typography>
          )}
          {!!value?.length && (
            <IconButton size="small" sx={{ marginLeft: 'auto' }}>
              <ClearIcon onClick={handleOnClear} />
            </IconButton>
          )}
          <ArrowDropDown style={{ width: 24, height: 24, marginLeft: !!value?.length ? 1 : 'auto' }} />
        </Button>
      </Box>
      {!!arrayData.length && (
        <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
          <ClickAwayListener onClickAway={handleClose}>
            <div>
              {!!assetsAndGroups && <AssetAndGroupsButtons />}
              <Autocomplete
                open
                fullWidth={fullWidth}
                multiple
                onClose={(event, reason) => {
                  if (reason === 'escape') {
                    handleClose();
                  }
                }}
                value={pendingValue}
                onChange={(event, newValue, reason) => {
                  if (newValue?.find(e => e.id === '')) {
                    setPendingValue([]);
                    setValue([]);
                    return;
                  }
                  if (event.type === 'keydown' && event?.key === 'Backspace' && reason === 'removeOption') {
                    return;
                  }
                  // Create a map to count occurrences of each id
                  const idCounts = newValue.reduce((acc, item) => {
                    acc[item[idField]] = (acc[item[idField]] || 0) + 1;
                    return acc;
                  }, {});

                  // Filter out items that have duplicate ids
                  const filteredValue = newValue.filter(item => idCounts[item[idField]] === 1);

                  setPendingValue(filteredValue);
                }}
                disableCloseOnSelect
                disableListWrap
                PopperComponent={PopperComponent}
                renderTags={() => null}
                noOptionsText="No options"
                renderOption={(props, option) => {
                  // Manually check if the option is selected
                  const isSelected = pendingValue.some(selectedOption => selectedOption[idField] === option[idField]);

                  return (
                    <>
                      <ListItem
                        {...props}
                        key={option[idField]}
                        className={classes.listItem}
                        sx={{
                          backgroundColor: pendingValue?.length === 0 && option[idField] === '' ? theme.palette.selectedBackground.selector : 'unset'
                        }}
                      >
                        <Box
                          component={DoneIcon}
                          className={classes.check}
                          style={{
                            visibility: isSelected ? 'visible' : 'hidden'
                          }}
                        />
                        <Tooltip title={option[fieldToShow]?.length > 27 ? option[fieldToShow] : null}>
                          <Box className={classes.title}>
                            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }} variant={'body1'}>
                              {option[fieldToShow]}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </ListItem>
                      {!option[idField] && <Divider />}
                    </>
                  );
                }}
                options={[...arrayData].sort((a, b) => {
                  // Ensure the top option (e.g., 'All Asset Groups') is always first
                  const topOptionId = ''; // ID of the top option
                  if (a.id === topOptionId) return -1; // 'All Asset Groups' comes first
                  if (b.id === topOptionId) return 1;
                  // Display the selected labels first.
                  let ai = value.indexOf(a);
                  ai = ai === -1 ? value.length + arrayData.indexOf(a) : ai;
                  let bi = value.indexOf(b);
                  bi = bi === -1 ? value.length + arrayData.indexOf(b) : bi;
                  return ai - bi;
                })}
                getOptionLabel={option => option[fieldToShow]}
                renderInput={params => (
                  <StyledInput ref={params.InputProps.ref} inputProps={params.inputProps} autoFocus placeholder={`Filter ${dataNameSingular}s`} />
                )}
              />
            </div>
          </ClickAwayListener>
        </StyledPopper>
      )}
    </React.Fragment>
  );
}

export default NRAutocomplete;
