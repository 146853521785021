import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  CustomPaging,
  EditingState,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSelection,
  IntegratedSorting,
  PagingState,
  SelectionState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  DragDropProvider,
  Grid,
  PagingPanel,
  Table,
  TableColumnReordering,
  TableColumnVisibility,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableHeaderRow,
  TableSelection,
  Toolbar,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TableComponent } from '../NRReactGridCustomComponents/TableComponent/TableComponent';
import { CellComponent, NoDataCellComponent } from '../NRReactGridCustomComponents/CellComponent/CellComponent';
import SortLabel from '../NRReactGridCustomComponents/SortLabel/SortLabel';
import { HeaderCell } from '../NRReactGridCustomComponents/HeaderCell/HeaderCell';
import CopyToClipboardBanner from '../NRReactGridCustomComponents/CopyToClipboardBanner/CopyToClipboardBanner';
import NRReactGridStyles from './NRReactGrid.styles';
import { isEmpty } from '../../utils/utils';
import clsx from 'clsx';
import { FilterCell } from '../NRDeviceInventory/NRDeviceInventoryComponents/FilterCell/FilterCell';
import Avatar from '@mui/material/Avatar';
import ToggleComponent from '../NRReactGridCustomComponents/FilterToggleButtonComponent';
import { Action, Getter } from '@devexpress/dx-react-core';
import useNRMutation from '../../graphql/UseNRMutation';
import { CONSTANTS } from '../../constants/constants';
import { PLACEHOLDER_MUTATION } from '../../graphql/queries/default';
import EditRowCustomCellComponent from '../NRReactGridCustomComponents/TableEdit/EditRowCustomCellComponent';
import { Command } from '../NRReactGridCustomComponents/TableEdit/CommandComponent/CommandComponent';
import HeaderCellComponent from '../NRReactGridCustomComponents/TableEdit/HeaderCellComponent';
import EditColumnCustomCellComponent from '../NRReactGridCustomComponents/TableEdit/EditColumnCustomCellComponent';
import NRColumnSettingsIcons from '../../NRIcons/NRColumnSettingsIcons';
import IconButton from '@mui/material/IconButton';
import { CursorPagerComponent } from './plugins/CursorPagination';
import NRFilterListIcon from '../../NRIcons/NRFilterListIcon';
import ToggleButton from '@mui/material/ToggleButton';
import debounce from 'lodash.debounce';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import RowComponent from '../NRReactGridCustomComponents/RowComponent/RowComponent';
import Box from '@mui/material/Box';
import { GridContext } from './NRReactGridContext';
import useLocalStorageService from '../../services/local';

const useStyles = makeStyles(NRReactGridStyles);

function NRReactGrid(
  {
    shouldClearAllFilters,
    rows = [],
    columns,
    columnExtensions,
    integratedSortingColumnExtension,
    pageSizes = [10, 25, 50, 100, 200],
    pageSize = 25,
    title,
    titleRightIcon,
    loading = false,
    statesArray,
    providersArray,
    renderersArray,
    selectionCell,
    showTitle = true,
    showSelectAll = true,
    showIntegratedSelection = true,
    showIntegratedPagination = true,
    customClass,
    variant = 'outlined',
    elevation = 1,
    titleExtraComponent,
    preSettedFilters,
    tableIcon,
    showFilterSelector = true,
    allowEdit = true,
    isTableEditable = false,
    hasExtraActions = false,
    qlQuery,
    qlVersion = 'v2',
    updateAfterUpdate,
    customTableFooter,
    allowColumnHiding = true,
    allowColumnReOrdering = true,
    noDataComponent,
    infoCellProvider,
    defaultSortingColumn,
    defaultSortingOrder = 'desc',
    getDataFrom,
    hasCursorPagination = false,
    totalCount,
    handleFetchMore,
    pageInfo,
    onSortingChange,
    handleFiltersChange,
    onChangeFiltersVisibility,
    columnsFiltersVisibleByDefault = false,
    isColumnFilterVisible,
    tableKey,
    fullHeight = false,
    isVirtualTable = false,
    scrollToRowId,
    addDefaultSorting = true,
    externalHiddenColumnNames,
    tableStorageId
  },
  ref
) {
  const [selection, setSelection] = useState([]);
  const [filters, setFilters] = useState(preSettedFilters || []);
  const [sorting, setSorting] = useState();
  const [editingIds, setEditingIds] = useState([]);
  const [defaultColumnOrder] = useState(columns.map(column => column.name));
  const [currentPage, setCurrentPage] = useState(0);

  const { shouldClearSelection, setShouldClearSelection } = useContext(GridContext);
  const [sortingColumnExtension] = useState(integratedSortingColumnExtension);
  const classes = useStyles();

  const vtRef = useRef();

  const { getStorageKeyData, setStorageKeyData } = useLocalStorageService();
  const assetSettingsStoredData = getStorageKeyData(tableStorageId);

  const [filtersVisibilityValue, setFiltersVisibilityValue] = useState(
    assetSettingsStoredData?.['filtersVisibilityValue'] != null ? assetSettingsStoredData?.['filtersVisibilityValue'] : columnsFiltersVisibleByDefault
  );
  const [tablePageSize, setTablePageSize] = useState(assetSettingsStoredData?.['first'] || pageSize);

  useEffect(() => {
    if (assetSettingsStoredData?.['first'] !== tablePageSize) {
      setStorageKeyData(tableStorageId, { key: 'first', value: tablePageSize });
    }
  }, [tablePageSize]);

  useEffect(() => {
    if (scrollToRowId != null) {
      vtRef.current.scrollToRow(scrollToRowId - 1);
    }
  }, [scrollToRowId]);

  useEffect(() => {
    if (shouldClearSelection) {
      setSelection([]);
      setShouldClearSelection(false);
    }
  }, [shouldClearSelection]);

  useEffect(() => {
    if (!!preSettedFilters?.length) {
      setFilters(preSettedFilters);
      if (handleFiltersChange) {
        debounceHandleFiltersChange.current(preSettedFilters);
      }
    }
  }, [preSettedFilters]);

  useEffect(() => {
    if (isColumnFilterVisible == null) return;
    isColumnFilterVisible !== filtersVisibilityValue && setFiltersVisibilityValue(isColumnFilterVisible);
  }, [isColumnFilterVisible]);

  /*GraphQL Config*/
  const [postRequestQl, result] = useNRMutation(
    qlQuery || PLACEHOLDER_MUTATION,
    getDataFrom,
    CONSTANTS.TABLE_UPDATE.SUCCESS_MESSAGE,
    CONSTANTS.TABLE_UPDATE.ERROR_MESSAGE
  );

  const handleSaveRow = (id, data, setRowEditOff) => {
    postRequestQl({
      variables: { args: { id: id, ...data } },
      context: { version: qlVersion },
      update(cache, { data }) {
        if (!!updateAfterUpdate) {
          updateAfterUpdate(cache, { id: id, data });
        }
        if (!!setRowEditOff) setRowEditOff();
      }
    });
  };

  useImperativeHandle(ref, () => ({
    refreshPagination: () => setCurrentPage(0),
    tablePageSize,
    filters
  }));

  useEffect(() => {
    if (result && !isEmpty(result)) {
      const index = rows.findIndex(e => e.id === result.id);
      if (index > -1) {
        setEditingIds(editingIds.filter(e => e !== index));
      }
    }
  }, [result]);

  useEffect(() => {
    shouldClearAllFilters && setFilters([]);
  }, [shouldClearAllFilters]);

  const [localDefaultHiddenColumnNames] = useState(
    assetSettingsStoredData?.['hiddenColumnNames'] ||
      columns
        .map(e => {
          if (e.hideColumn) return e.name;
        })
        .filter(Boolean)
  );
  const infoCol = {
    title: { value: '' },
    name: 'info',
    hideFilter: true,
    disableHideColumn: true
  };

  const infoColExt = {
    columnName: 'info',
    align: 'center',
    width: 48
  };

  if (!!infoCellProvider) {
    if (columns.every(col => col.name !== infoCol.name)) {
      columns.unshift(infoCol);
    }
    if (columnExtensions.every(colExt => colExt.columnName !== infoColExt.columnName)) {
      columnExtensions.unshift(infoColExt);
    }
  }

  const [tableColumnVisibilityColumnExtensions] = useState(
    columns
      .map(e => {
        if (e.disableHideColumn) return { columnName: e.name, togglingEnabled: false };
      })
      .filter(Boolean)
  );

  const handlePageSizeChange = size => {
    handleFetchMore({ first: size });
    setCurrentPage(0);
    setTablePageSize(size);
  };

  const handlePageChange = p => {
    if (p > currentPage) {
      //clicked next page
      if (pageInfo.hasNextPage && pageInfo.endCursor) {
        handleFetchMore({ after: pageInfo.endCursor });
      }
      if (pageInfo.nextPageToken) {
        // this is cursorV3
        handleFetchMore({ after: pageInfo.nextPageToken });
      }
    } else if (p < currentPage && p !== 0) {
      //clicked prev page
      if (pageInfo.startCursor) {
        handleFetchMore({ before: pageInfo.startCursor });
      }
      if (pageInfo.prevPageToken) {
        // this is cursorV3
        handleFetchMore({ before: pageInfo.prevPageToken });
      }
    } else if (p === 0) {
      handleFetchMore({ first: tablePageSize });
    }
    setCurrentPage(p);
  };

  const handleOnSortingChange = value => {
    setSorting(value);
    setCurrentPage(0);
    if (onSortingChange) {
      onSortingChange(value[0]?.columnName, value[0]?.direction);
    }
  };

  const debounceHandleFiltersChange = useRef(
    debounce(filters => {
      handleFiltersChange(filters);
    }, 800)
  );

  const handleFilterChange = filters => {
    setFilters(filters);
    if (handleFiltersChange) {
      debounceHandleFiltersChange.current(filters);
    }
  };

  const filteringColumnExtensions = (value, filter, row, column) => {
    if (!filter.value) return true;
    return IntegratedFiltering.defaultPredicate(value.length || value, filter, row);
  };
  const filteringColumnExtensionsMultiselect = (value, filter, row, column) => {
    if (!filter.value) return true;
    if (!filter.value?.includes('&')) {
      return IntegratedFiltering.defaultPredicate(value, filter, row);
    } else {
      let values = filter.value.split('&');
      if (!row[filter.columnName]) return false;
      if (values.includes(row[filter.columnName].toString())) return true;
    }
    return false;
  };

  const multiselectColumnsFilteringExtensions = columns
    .map(e => {
      if (e?.type === 'multiselectFE') {
        return {
          columnName: e.name,
          predicate: filteringColumnExtensionsMultiselect
        };
      }
    })
    .filter(Boolean);

  const [integratedFilteringColumnExtensions] = useState([
    { columnName: 'correlations', align: 'center', predicate: filteringColumnExtensions },
    ...multiselectColumnsFilteringExtensions
  ]);

  const handleFiltersVisibility = () => {
    setFiltersVisibilityValue(!filtersVisibilityValue);
    if (onChangeFiltersVisibility && isColumnFilterVisible) {
      onChangeFiltersVisibility(!isColumnFilterVisible);
    }
    setStorageKeyData(tableStorageId, { key: 'filtersVisibilityValue', value: !filtersVisibilityValue });
  };

  const commitChanges = ({ changed, ...rest }) => {
    const rowIndex = Object.keys(changed)[0];
    const row = rows[rowIndex];
    handleSaveRow(row.id, changed[rowIndex], () => setEditingIds(editingIds.filter(e => e !== +rowIndex)));
  };

  const editingStateRef = useRef(null);

  const handleOnSave = id => {
    editingStateRef.current.commitChangedRows({ rowIds: [id] });
  };

  const handleDiscardChanges = id => {
    editingStateRef.current.cancelChangedRows({ rowIds: [id] });
    editingStateRef.current.stopEditRows({ rowIds: [id] });
  };

  useEffect(() => {
    setSelection([]);
  }, [rows]);

  useEffect(() => {
    if (tableStorageId && externalHiddenColumnNames) {
      setStorageKeyData(tableStorageId, { key: 'hiddenColumnNames', value: [...externalHiddenColumnNames] });
    }
  }, [externalHiddenColumnNames]);

  const renderNoDataCell = useCallback(props => <NoDataCellComponent {...props} loading={loading} />, [loading]);

  return (
    <Paper
      variant={variant}
      {...(variant === 'elevation' && { elevation })}
      className={clsx(classes.mainPaper, customClass, { [classes.mainPaperFullHeight]: fullHeight })}
    >
      <div className={classes.headerContainer}>
        {showTitle && (
          <div className={classes.header}>
            {!!tableIcon && (
              <Avatar variant="rounded" className={classes.avatar}>
                {tableIcon}
              </Avatar>
            )}
            <Box display={'flex'} alignItems={'center'}>
              <Typography data-testid="grid-title" component="div" className={classes.title}>
                {title}
              </Typography>
              <Box>{titleRightIcon}</Box>
            </Box>
            {!!titleExtraComponent && titleExtraComponent}
          </div>
        )}
        {!!selection.length && <CopyToClipboardBanner selectedItems={selection} setSelection={setSelection} rows={rows} />}
        {fullHeight && <Divider sx={{ width: 'calc(100% - 32px)', alignSelf: 'center' }} />}
      </div>
      <>
        <Grid rows={rows} columns={columns} key={tableKey || 'table'}>
          <SelectionState selection={selection} onSelectionChange={setSelection} />
          <FilteringState filters={filters} onFiltersChange={handleFilterChange} />
          <SortingState
            sorting={sorting}
            onSortingChange={handleOnSortingChange}
            {...(!!addDefaultSorting && {
              defaultSorting: [
                { columnName: defaultSortingColumn || columns[!!infoCellProvider ? 1 : 0]?.name, direction: defaultSortingOrder?.toLowerCase() }
              ]
            })}
          />
          {showIntegratedPagination && !hasCursorPagination && <PagingState defaultCurrentPage={0} defaultPageSize={tablePageSize} />}
          {showIntegratedPagination && hasCursorPagination && (
            <PagingState
              defaultCurrentPage={0}
              defaultPageSize={tablePageSize}
              onCurrentPageChange={handlePageChange}
              currentPage={currentPage}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
          {(isTableEditable || hasExtraActions) && (
            <EditingState onCommitChanges={commitChanges} onEditingRowIdsChange={setEditingIds} editingRowIds={editingIds} ref={editingStateRef} />
          )}
          {/* place it after IntegratedPaging for the select all to affect all pages */}
          {statesArray}
          <IntegratedSelection />
          {!hasCursorPagination && <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />}
          {!hasCursorPagination && <IntegratedSorting columnExtensions={sortingColumnExtension} />}
          {[...providersArray, infoCellProvider]}
          {showIntegratedPagination && !hasCursorPagination && <IntegratedPaging />}
          {showIntegratedPagination && hasCursorPagination && <CustomPaging totalCount={totalCount} />}
          {!isVirtualTable && (
            <Table
              columnExtensions={columnExtensions}
              tableComponent={TableComponent}
              cellComponent={CellComponent}
              noDataCellComponent={renderNoDataCell}
              rowComponent={RowComponent}
            />
          )}
          {isVirtualTable && (
            <VirtualTable
              columnExtensions={columnExtensions}
              tableComponent={TableComponent}
              cellComponent={CellComponent}
              noDataCellComponent={renderNoDataCell}
              rowComponent={RowComponent}
              ref={vtRef}
            />
          )}
          {allowColumnReOrdering && (
            <DragDropProvider
              columnComponent={({ column }) => {
                return (
                  // to support value based column title
                  <DragDropProvider.Column {...{ column: { disableEdit: column.disableEdit, name: column.name, title: column.title.value } }} />
                );
              }}
            />
          )}
          {allowColumnReOrdering && <TableColumnReordering defaultOrder={defaultColumnOrder} />}
          <TableHeaderRow showSortingControls sortLabelComponent={SortLabel} cellComponent={HeaderCell} />
          {filtersVisibilityValue && (
            <TableFilterRow cellComponent={FilterCell} toggleButtonComponent={ToggleComponent} {...(showFilterSelector && { showFilterSelector })} />
          )}
          {allowColumnHiding && (
            <TableColumnVisibility
              defaultHiddenColumnNames={localDefaultHiddenColumnNames}
              columnExtensions={tableColumnVisibilityColumnExtensions}
              onHiddenColumnNamesChange={hiddenColumnNames => {
                if (tableStorageId) {
                  setStorageKeyData(tableStorageId, { key: 'hiddenColumnNames', value: [...hiddenColumnNames] });
                }
              }}
              {...(externalHiddenColumnNames && {
                hiddenColumnNames: externalHiddenColumnNames
              })}
            />
          )}
          {allowColumnHiding && <Toolbar rootComponent={props => <Toolbar.Root {...props} style={{ minHeight: 0, borderBottom: 'unset' }} />} />}
          {allowColumnHiding && (
            <ColumnChooser
              toggleButtonComponent={({ onToggle, buttonRef }) => (
                <>
                  <Tooltip title={'Custom columns'}>
                    <IconButton
                      onClick={onToggle}
                      ref={buttonRef}
                      color="secondary"
                      size="small"
                      className={clsx(classes.columnSettingsIconAlone, {
                        [classes.columnSettingsIconSelection]: !!selection.length
                      })}
                    >
                      <NRColumnSettingsIcons />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Column filtering'}>
                    <ToggleButton
                      value="filtersVisibility"
                      selected={filtersVisibilityValue}
                      onChange={handleFiltersVisibility}
                      classes={{ root: classes.visibilityFilters }}
                      className={clsx(classes.filterHidingIconAlone, {
                        [classes.columnSettingsIconSelection]: !!selection.length,
                        [classes.hasFilters]: !!filters?.length
                      })}
                    >
                      <NRFilterListIcon />
                    </ToggleButton>
                  </Tooltip>
                </>
              )}
              itemComponent={({ onToggle, disabled, item }) => {
                return (
                  // to support value based column title
                  <ColumnChooser.Item
                    {...{
                      disabled,
                      onToggle,
                      item: {
                        hidden: item.hidden,
                        column: { name: item.column.name, title: item.column.title.value, getCellValue: item.column.getCellValue }
                      }
                    }}
                  />
                );
              }}
              overlayComponent={(...args) => {
                return <ColumnChooser.Overlay elevation={3} {...args[0]} {...args[1]} />;
              }}
            />
          )}
          {(isTableEditable || hasExtraActions) && (
            <TableEditRow
              cellComponent={props => (
                <EditRowCustomCellComponent
                  {...props}
                  className={classes.editingCell}
                  onKeyDown={e => {
                    if (e.keyCode === 13) {
                      // Enter
                      handleOnSave(props?.tableRow?.rowId);
                    }
                    if (e.keyCode === 27) {
                      // Escape
                      handleDiscardChanges(props?.tableRow?.rowId);
                    }
                  }}
                />
              )}
              rowComponent={props => <TableEditRow.Row {...props} className={classes.editingRow} />}
            />
          )}
          {(isTableEditable || hasExtraActions) && (
            <TableEditColumn
              showEditCommand={!!isTableEditable}
              cellComponent={EditColumnCustomCellComponent}
              headerCellComponent={HeaderCellComponent}
              commandComponent={Command}
              showAddCommand
              width={82}
            />
          )}
          {renderersArray}
          {showIntegratedSelection && (
            <TableSelection showSelectAll={showSelectAll} {...(!!selectionCell && !!showIntegratedSelection && { cellComponent: selectionCell })} />
          )}
          <Getter
            name="tableColumns"
            computed={({ tableColumns }) => {
              return [
                ...tableColumns.filter(c => c.type.toString() !== 'Symbol(editCommand)'),
                ...tableColumns.filter(c => c.type.toString() === 'Symbol(editCommand)')
              ];
            }}
          />
          <Action name="cancelChangedRows" action={({ rowIds }) => setEditingIds(editingIds.filter(e => e !== rowIds[0]))} />
          {showIntegratedPagination && !hasCursorPagination && <PagingPanel pageSizes={pageSizes} />}
          {showIntegratedPagination && hasCursorPagination && <PagingPanel pageSizes={pageSizes} containerComponent={CursorPagerComponent} />}
        </Grid>
        {!!customTableFooter && customTableFooter}
      </>
    </Paper>
  );
}
export default forwardRef(NRReactGrid);
