import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useContext } from 'react';
import { AutocompleteGroupsAndAssetsContext } from './NRAutocompleteGroupsAndAssetsContextWrapper';
import { NewSearchContext } from '../../../../pages/NRSearch/NRNewSearch/NRNewSearchContextWrapper';

function AssetAndGroupsButtons() {
  const theme = useTheme();
  const { assetsSelected, setAssetsSelected } = useContext(AutocompleteGroupsAndAssetsContext);
  const { setAssetFilter, setGroupFilter } = useContext(NewSearchContext);

  const handleSetAssetsSelected = () => {
    setAssetsSelected(!assetsSelected);
    setAssetFilter([]);
    setGroupFilter([]);
  };

  return (
    <Box sx={{ height: 52, borderBottom: `1px solid ${theme.palette.divider}` }} py={1} px={2}>
      <ButtonGroup variant="outlined" aria-label="outlined primary button group" sx={{ width: '100%' }}>
        <Button
          sx={{ textTransform: 'none', width: '50%' }}
          variant={!!assetsSelected ? 'contained' : 'outlined'}
          color="secondary"
          onClick={handleSetAssetsSelected}
        >
          <Typography>Assets</Typography>
        </Button>
        <Button
          sx={{ textTransform: 'none', width: '50%' }}
          variant={!assetsSelected ? 'contained' : 'outlined'}
          color="secondary"
          onClick={handleSetAssetsSelected}
        >
          <Typography>Groups</Typography>
        </Button>
      </ButtonGroup>
    </Box>
  );
}

export default AssetAndGroupsButtons;
