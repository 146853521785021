import { createContext, useEffect, useState } from 'react';
import { SBOM_TYPES } from '../components/SBOM/SBOM.constants';

export const AssetsContext = createContext({
  tasks: null,
  setTasks: () => {},
  updateStatus: () => {},
  currentAsset: null,
  setCurrentAsset: () => {},
  isAssetExcluded: null,
  setRecentlyUploadedAsset: () => {},
  recentlyUploadedAsset: null
});

function NRAssetsDataShare({ children }) {
  const [tasks, setTasks] = useState([]);
  const [currentAsset, setCurrentAsset] = useState(null);
  const [isAssetExcluded, setIsAssetExcluded] = useState(null);
  const [recentlyUploadedAsset, setRecentlyUploadedAsset] = useState();

  const updateStatus = (status, uploadUrl, operationId) => {
    if (uploadUrl) {
      const currentUploadIndex = tasks.findIndex(e => e.uploadUrl === uploadUrl);
      if (currentUploadIndex !== -1) {
        const newTasks = [...tasks];
        newTasks[currentUploadIndex].status = status;
        setTasks(newTasks);
      }
    } else if (operationId) {
      const currentDownloadIndex = tasks.findIndex(e => e.operationId === operationId);
      if (currentDownloadIndex !== -1) {
        const newTasks = [...tasks];
        newTasks[currentDownloadIndex].status = status;
        setTasks(newTasks);
      }
    }
  };

  useEffect(() => {
    if (currentAsset && SBOM_TYPES.includes(currentAsset.type)) {
      setIsAssetExcluded(true);
    } else if (currentAsset === null || (isAssetExcluded && !SBOM_TYPES.includes(currentAsset.type))) {
      setIsAssetExcluded(false);
    }
  }, [currentAsset]);

  const defaultValues = {
    tasks,
    setTasks,
    updateStatus,
    currentAsset,
    setCurrentAsset,
    isAssetExcluded,
    recentlyUploadedAsset,
    setRecentlyUploadedAsset
  };
  return <AssetsContext.Provider value={defaultValues}>{children}</AssetsContext.Provider>;
}

export default NRAssetsDataShare;
