import styled, { keyframes } from 'styled-components';
import { CircularProgress } from '@mui/material';
import { memo } from 'react';

const motion = () => keyframes`
  0% {
      transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
`;

const NRLoader = memo(({ disableShrink = false }) => (
  <Wrapper>
    <CircularProgress disableShrink={disableShrink} variant={'indeterminate'} />
  </Wrapper>
));

export default NRLoader;
