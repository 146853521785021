import { DataTypeProvider } from '@devexpress/dx-react-grid';
import Box from '@mui/material/Box';
import React, { useContext } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { AssetGroupContext } from '../../../AssetGroup/assetGroupContextWrapper';
import { CONSTANTS } from '../../../../constants/constants';
import WithPermissions from '../../../Permissions/WithPermissions';

const LinkFormatter = ({ row }) => {
  const { selectedGroups, setSelectedGroups, disableEdit } = useContext(AssetGroupContext);

  const handleRemoveGroup = () => {
    setSelectedGroups(selectedGroups.filter(e => e.id !== row.id));
  };

  return (
    <WithPermissions permission={CONSTANTS.PERMISSIONS.WRITE_GROUPS}>
      {!disableEdit && (
        <Box key={row?.id}>
          <IconButton aria-label="Close" onClick={handleRemoveGroup} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </WithPermissions>
  );
};
export const NRAssetGroupDeleteProvider = props => <DataTypeProvider formatterComponent={LinkFormatter} {...props} />;
