import { useContext } from 'react';
import { UserContext } from '../App';
import { useSnackbar } from 'notistack';
import CustomError from '../components/CustomError/CustomError';

export const LOCAL_STORAGE_CONSTANTS = {
  TABLE: {
    FILTER_VISIBILITY_VALUE: 'filtersVisibilityValue',
    SORT: 'sort',
    FIRST: 'first',
    COLUMN_ORDER: 'columnOrder',
    HIDDEN_COLUMN_NAMES: 'hiddenColumnNames'
  }
};

const useLocalStorageService = () => {
  const { email, currentOrgId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const getLocalStorageKey = () => {
    if (!email || !currentOrgId) {
      enqueueSnackbar(<CustomError message={'User or organization information not found.'} />, { variant: 'error', preventDuplicate: true });
      return null;
    }
    return `${email}_${currentOrgId}`;
  };

  const getLocalStorageData = () => {
    const localStorageKey = getLocalStorageKey();
    if (!localStorageKey) return null;

    try {
      const data = localStorage.getItem(localStorageKey);
      return data ? JSON.parse(data) : {};
    } catch (error) {
      enqueueSnackbar(<CustomError message={`Error recovering data for: "${localStorageKey.split('_')[0]}": ${error}`} />, {
        variant: 'error',
        preventDuplicate: true
      });
      return {};
    }
  };

  const setLocalStorageData = data => {
    const localStorageKey = getLocalStorageKey();
    if (!localStorageKey) return;

    try {
      localStorage.setItem(localStorageKey, JSON.stringify(data));
    } catch (error) {
      enqueueSnackbar(<CustomError message={`Error saving data for: "${localStorageKey.split('_')[0]}": ${error}`} />, {
        variant: 'error',
        preventDuplicate: true
      });
    }
  };

  const getStorageKeyData = key => {
    const storageData = getLocalStorageData();
    return storageData ? storageData[key] : null;
  };

  const setStorageKeyData = (key, data) => {
    if (key == null) {
      console.log('Storage Key not found.');
      return;
    }
    const keyData = getLocalStorageData() || {};
    let dataToSave = { ...keyData[key] };
    dataToSave[data.key] = data.value;

    keyData[key] = dataToSave;
    setLocalStorageData(keyData);
  };

  const clearStorageKeyData = key => {
    const keyData = getLocalStorageData();
    if (keyData && key in keyData) {
      delete keyData[key];
      setLocalStorageData(keyData);
    }
  };

  const clearOrgStorageData = () => {
    const storageKey = getLocalStorageKey();
    if (storageKey) {
      localStorage.removeItem(storageKey);
    }
  };

  const clearUserStorageData = () => {
    if (email) {
      let localStorageKeys = Object.keys(localStorage);

      localStorageKeys.forEach(localStorageKey => {
        if (localStorageKey.startsWith(email)) {
          localStorage.removeItem(localStorageKey);
        }
      });
    }
  };

  return {
    getLocalStorageData,
    setLocalStorageData,
    getStorageKeyData,
    setStorageKeyData,
    clearStorageKeyData,
    clearOrgStorageData,
    clearUserStorageData
  };
};

export default useLocalStorageService;
