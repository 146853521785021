export const decodeParam = value => {
  try {
    const parsedValue = JSON.parse(value);

    // If it's an array, we return it as is (array of objects)
    if (Array.isArray(parsedValue)) {
      return parsedValue;
    }

    // If it's a single object, return it as is
    return parsedValue;
  } catch (e) {
    // If parsing fails (not a valid JSON), return the value as is
    return value;
  }
};

export const encodeParam = value => {
  // Check if the value is an array of objects or a single object
  if (Array.isArray(value)) {
    // If it's an array, stringify each object inside the array
    return JSON.stringify(value);
  }
  if (typeof value === 'object') {
    // If it's a single object, just stringify it
    return JSON.stringify(value);
  }
  return value; // For primitive types (string, number, etc.)
};

export const handleSetArrayObjParam = (setter, value) => {
  setter(prev => (JSON.stringify(prev) === JSON.stringify(value) ? prev : value));
};
