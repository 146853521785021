import { DataTypeProvider } from '@devexpress/dx-react-grid';
import ActionsProviderStyles from './ActionsProvider.styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import {numberFilterOperations} from "../../../NRReactGrid/NRReactGrid.utils";
const useStyles = makeStyles(ActionsProviderStyles);

export const ActionsFormatter = ({ gridActions, row }) => {
  const classes = useStyles();

  return (
    <>
      {row.actions?.map((each, actionIndex) => (
        <Tooltip title={!!each.disabled ? '' : each.title} key={each.title}>
          <span>
            <Button
              disabled={!!each.disabled}
              classes={{ root: classes.button }}
              aria-label={each.title}
              {...(each.action && { onClick: ev => each.action(ev, gridActions, row) })}
              {...(!!each.customStyles && { style: each.customStyles })}
            >
              {!each.action
                ? React.cloneElement(each.icon, {
                    row,
                    actionIndex,
                    disabled: !!each.disabled
                  })
                : each.icon}
            </Button>
          </span>
        </Tooltip>
      ))}
    </>
  );
};
export const ActionsProvider = props => {
  return <DataTypeProvider formatterComponent={ActionsFormatter} availableFilterOperations={numberFilterOperations} {...props} />;
};
