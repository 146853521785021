export const numberFilterOperations = ['equal', 'notEqual', 'greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual'];
export const availableFilterOperations = [
  'contains',
  'notContains',
  'startsWith',
  'endsWith',
  'equal',
  'notEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'lessThan',
  'lessThanOrEqual'
];

export const getFilterOperationCamelCase = filterOpString => {
  return [...availableFilterOperations, ...numberFilterOperations].find(op => op?.toLowerCase() === filterOpString?.toLowerCase());
};
