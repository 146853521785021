function NRSPDXIcon({ height = '20', width = '21', isWhite = false, inheritColor = false, opacity = 1 }) {
  const colorWhite = '#FAFAFA';
  const colorBlack = 'black';
  const opacityBlack = 0.6;
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4165 18.3332H7.91327L11.2642 14.9234V10.5436H15.6734L19.0538 7.16327V1.7253L19.0832 1.6959H19.0538V1.6665L19.0248 1.6959H2.4165V18.3332ZM15.3207 5.45839L6.20839 14.5707V5.4584L15.3207 5.45839Z"
        {...(!inheritColor && { fill: isWhite ? colorWhite : colorBlack, fillOpacity: isWhite ? 1 : opacityBlack })}
        {...(inheritColor && { fill: 'currentColor', fillOpacity: opacity })}
      />
      <path
        d="M12.2049 11.4843H19.0538V18.3332H12.2049V11.4843Z"
        {...(!inheritColor && { fill: isWhite ? colorWhite : colorBlack, fillOpacity: isWhite ? 1 : opacityBlack })}
        {...(inheritColor && { fill: 'currentColor', fillOpacity: opacity })}
      />
    </svg>
  );
}
export default NRSPDXIcon;
