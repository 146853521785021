import { createContext, useState } from 'react';

const ARTIFACTS = [
  { artifact: 'CERTIFICATE', displayName: 'Certificate' },
  { artifact: 'COMPONENT', displayName: 'Component' },
  { artifact: 'CVE', displayName: 'CVE' },
  { artifact: 'HASH', displayName: 'Hash' },
  { artifact: 'KERNELCONFIG', displayName: 'Kernel Config' },
  { artifact: 'LINUXAUTH', displayName: 'Linux Auth' },
  { artifact: 'SHADOW', displayName: 'Linux Shadow' },
  { artifact: 'PRIVATEKEY', displayName: 'Private Key' },
  { artifact: 'PUBLICKEY', displayName: 'Public Key' },
  { artifact: 'SERVICES', displayName: 'Service' },
  { artifact: 'BINARY_PROTECTIONS', displayName: 'Binary Protection' },
  { artifact: 'BUSYBOXCONF', displayName: 'Busybox Config' },
  { artifact: 'PUBLICPRIVATEKEYPAIRS', displayName: 'Crypto Key Pair' },
  { artifact: 'ENCRYPEDPEM', displayName: 'Encrypted PEM' },
  { artifact: 'GROUP', displayName: 'Group' },
  { artifact: 'INITTAB', displayName: 'Inittab' },
  { artifact: 'KMODINFO', displayName: 'Kernel Module' },
  { artifact: 'PASSWD', displayName: 'Linux Passwd' },
  { artifact: 'SONAME', displayName: 'Shared Object Name' },
  { artifact: 'MALWARE', displayName: 'Malware' },
  { artifact: 'SHEBANG', displayName: 'Script Shebang' },
  { artifact: 'SSHPUBKEY', displayName: 'SSH Public Key' },
  { artifact: 'SYSCONFIG', displayName: 'Sysconfig' },
  { artifact: 'SYSCTLCONF', displayName: 'Sysctl Config' }
];
export const ANY_ARTIFACT = { artifact: 'any', displayName: 'Any' };

export const NewSearchContext = createContext({
  searchStr: null,
  setSearchStr: () => {},
  previousSearch: null,
  setPreviousSearch: () => {},
  onClearFilter: () => {},
  pageSize: null,
  setPageSize: () => {},
  results: null,
  setResults: () => {},
  currentPage: null,
  setCurrentPage: () => {},
  isLoading: null,
  setIsLoading: () => {},
  artifacts: null,
  selectedArtifacts: null,
  setSelectedArtifacts: () => {},
  assetFilter: null,
  setAssetFilter: () => {},
  groupFilter: null,
  setGroupFilter: () => {},
  allAssets: null,
  setAllAssets: () => {}
});

export const emptySearch = {
  query: '',
  artifacts: [],
  assets: []
};

function NRNewSearchContextWrapper({ children }) {
  const [searchStr, setSearchStr] = useState('');
  const [previousSearch, setPreviousSearch] = useState(emptySearch);
  const [pageSize, setPageSize] = useState(20);
  const [results, setResults] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedArtifacts, setSelectedArtifacts] = useState([ANY_ARTIFACT.artifact]);
  const [artifacts] = useState(ARTIFACTS);
  const [assetFilter, setAssetFilter] = useState([]);
  const [groupFilter, setGroupFilter] = useState([]);
  const [allAssets, setAllAssets] = useState([]);

  const onClearFilter = () => {
    setSearchStr('');
  };

  const defaultValues = {
    searchStr,
    setSearchStr,
    previousSearch,
    setPreviousSearch,
    onClearFilter,
    pageSize,
    setPageSize,
    results,
    setResults,
    currentPage,
    setCurrentPage,
    isLoading,
    setIsLoading,
    selectedArtifacts,
    setSelectedArtifacts,
    artifacts,
    assetFilter,
    setAssetFilter,
    allAssets,
    setAllAssets,
    groupFilter,
    setGroupFilter
  };

  return <NewSearchContext.Provider value={defaultValues}>{children}</NewSearchContext.Provider>;
}

export default NRNewSearchContextWrapper;
