import { useContext } from 'react';
import { UserContext } from '../App';
import { useSnackbar } from 'notistack';
import CustomError from '../components/CustomError/CustomError';

const useSessionStorageService = () => {
  const { email, currentOrgId } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const getSessionStorageKey = () => {
    if (!email || !currentOrgId) {
      //enqueueSnackbar(<CustomError message={'User or organization information not found.'} />, { variant: 'error', preventDuplicate: true });
      return null;
    }
    return `${email}_${currentOrgId}`;
  };

  const getSessionStorageData = () => {
    const sessionStorageKey = getSessionStorageKey();
    if (!sessionStorageKey) return null;

    try {
      const data = sessionStorage.getItem(sessionStorageKey);
      return data ? JSON.parse(data) : {};
    } catch (error) {
      enqueueSnackbar(<CustomError message={`Error recovering data for: "${sessionStorageKey.split('_')[0]}": ${error}`} />, {
        variant: 'error',
        preventDuplicate: true
      });
      return {};
    }
  };

  const setSessionStorageData = data => {
    const sessionStorageKey = getSessionStorageKey();
    if (!sessionStorageKey) return;

    try {
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(data));
    } catch (error) {
      enqueueSnackbar(<CustomError message={`Error saving data for: "${sessionStorageKey.split('_')[0]}": ${error}`} />, {
        variant: 'error',
        preventDuplicate: true
      });
    }
  };

  const getSessionKeyData = key => {
    const rootData = getSessionStorageData();
    return rootData ? rootData[key] : null;
  };

  const setSessionKeyData = (key, value) => {
    const rootData = getSessionStorageData() || {};
    rootData[key] = value;
    setSessionStorageData(rootData);
  };

  const clearSessionKeyData = key => {
    const rootData = getSessionStorageData();
    if (rootData && key in rootData) {
      delete rootData[key];
      setSessionStorageData(rootData);
    }
  };

  const clearOrgSessionData = () => {
    const rootKey = getSessionStorageKey();
    if (rootKey) {
      sessionStorage.removeItem(rootKey);
    }
  };

  const clearUserSessionData = () => {
    if (email) {
      let sessionStorageKeys = Object.keys(sessionStorage);

      sessionStorageKeys.forEach(sessionStorageKey => {
        if (sessionStorageKey.startsWith(email)) {
          sessionStorage.removeItem(sessionStorageKey);
        }
      });
    }
  };

  return {
    getSessionStorageData,
    setSessionStorageData,
    getSessionKeyData,
    setSessionKeyData,
    clearSessionKeyData,
    clearOrgSessionData,
    clearUserSessionData
  };
};

export default useSessionStorageService;
