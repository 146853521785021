import { createContext, useEffect, useState } from 'react';
import * as React from 'react';
import AssetGroupProviderModal from '../NRReactGridCustomComponents/NRCustomProviders/AssetGroupProvider/AssetGroupProviderModal';

export const AssetGroupContext = createContext({
  assetGroups: null,
  setAssetGroups: () => {},
  assetGroupsLoading: null,
  setAssetGroupsLoading: () => {},
  showManageGroupsModal: null,
  setShowManageGroupsModal: () => {},
  selectedAsset: null,
  setSelectedAsset: () => {},
  selectedAssets: null,
  setSelectedAssets: () => {},
  showManageGroupsAddBulkModal: null,
  setShowManageGroupsAddBulkModal: () => {},
  showManageGroupsRemoveAllModal: null,
  setShowManageGroupsRemoveAllModal: () => {},
  selectedGroups: null,
  setSelectedGroups: () => {},
  assetsRelayArgs: null,
  setAssetsRelayArgs: () => {},
  disableEdit: null,
  setDisableEdit: () => {},
  groupsModalContent: null,
  setGroupsModalContent: () => {} // { content: <Content />, title: 'String' }
});

function AssetGroupContextWrapper({ children }) {
  const [assetGroups, setAssetGroups] = useState([]);
  const [assetGroupsLoading, setAssetGroupsLoading] = useState(true);
  const [showManageGroupsModal, setShowManageGroupsModal] = useState(false);
  const [showManageGroupsAddBulkModal, setShowManageGroupsAddBulkModal] = useState(false);
  const [showManageGroupsRemoveAllModal, setShowManageGroupsRemoveAllModal] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();
  const [selectedAssets, setSelectedAssets] = useState();
  const [assetsRelayArgs, setAssetsRelayArgs] = useState();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupsModalContent, setGroupsModalContent] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);

  useEffect(() => {
    if (!showManageGroupsModal) {
      setSelectedGroups([]);
    }
  }, [showManageGroupsModal]);

  useEffect(() => {
    if (!showManageGroupsAddBulkModal) {
      setSelectedGroups([]);
    }
  }, [showManageGroupsAddBulkModal]);

  const defaultValues = {
    assetGroups,
    setAssetGroups,
    assetGroupsLoading,
    setAssetGroupsLoading,
    showManageGroupsModal,
    setShowManageGroupsModal,
    selectedAsset,
    setSelectedAsset,
    selectedAssets,
    setSelectedAssets,
    showManageGroupsAddBulkModal,
    setShowManageGroupsAddBulkModal,
    showManageGroupsRemoveAllModal,
    setShowManageGroupsRemoveAllModal,
    selectedGroups,
    setSelectedGroups,
    groupsModalContent,
    setGroupsModalContent,
    assetsRelayArgs,
    setAssetsRelayArgs,
    disableEdit,
    setDisableEdit
  };

  return <AssetGroupContext.Provider value={defaultValues}>{children}</AssetGroupContext.Provider>;
}

export default AssetGroupContextWrapper;
