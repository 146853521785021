import { createContext, useState } from 'react';

export const AutocompleteGroupsAndAssetsContext = createContext({
  assetsSelected: null,
  setAssetsSelected: () => {}
});

function NRAutocompleteGroupsAndAssetsContextWrapper({ children }) {
  const [assetsSelected, setAssetsSelected] = useState(true);

  const defaultValues = {
    assetsSelected,
    setAssetsSelected
  };

  return <AutocompleteGroupsAndAssetsContext.Provider value={defaultValues}>{children}</AutocompleteGroupsAndAssetsContext.Provider>;
}

export default NRAutocompleteGroupsAndAssetsContextWrapper;
